<template>
  <div class="bubble-chart-block">
    <ChartTitle
      :title="title"
      :hint="hint"
      :showOptions="showOptions"
      :getChart="getChart"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue'
import ChartTitle from './ChartTitle.vue'
import { useChart } from '@/use/dashboard'

export default defineComponent({
  name: 'BubbleChartBlock',
  components: { ChartTitle },
  props: {
    options: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    showOptions: { type: Boolean, default: false },
    getChart: { type: Function, default: () => {} },
  },
  setup (props) {
    const { chartRef, updateChart } = useChart(props.options)

    watch(
      () => props.options,
      () => {
        updateChart(props.options)
      },
    )
    return {
      chartRef,
    }
  },
})
</script>
