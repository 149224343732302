<template>
  <div class="age-consumption-statistics">
    <ChartTitle :showBorder="false" :getChart="getChart" />
    <div class="flex justify-center">
      <DonutChartBlock
        :options="getOptions(chartData.sumAmount)"
        title="年齡總消費金額"
        :subtitle="`$ ${sumValue(chartData.sumAmount)}`"
        class="flex-1"
      />
      <DonutChartBlock
        :options="getOptions(chartData.count)"
        title="年齡總消費單數"
        :subtitle="`${sumValue(chartData.count)}單`"
        class="flex-1"
      />
    </div>
    <div class="flex justify-center">
      <DonutChartBlock
        :options="getOptions(chartData.avgAmount)"
        title="年齡平均消費金額"
        class="flex-1"
      />
      <DonutChartBlock
        :options="getOptions(chartData.avgMemberCount)"
        title="年齡平均消費單數"
        class="flex-1"
      />
    </div>
    <div class="flex justify-end">
      <p
        class="flex items-center text-primary-100 cursor-pointer"
        @click="$router.push({ name: 'MemberAgeOverview' })"
      >
        看更多
        <img style="margin: auto" width="18" :src="ArrowRight">
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import DonutChartBlock from '@/components/Dashboard/DonutChartBlock.vue'
import { getAgeData, generateAgeConfig } from '../../common'
import { truncateDecimal } from '@/utils/dashboard'
import store from '@/store'
import ArrowRight from '@/assets/operateDashboard/arrow-right.svg'

export default defineComponent({
  name: 'AgeConsumptionStatistics',
  components: { ChartTitle, DonutChartBlock },
  setup () {
    const shopId = computed(() => store.getters.shop)
    const chartData = reactive({
      sumAmount: [],
      count: [],
      avgAmount: [],
      avgMemberCount: [],
    })
    const ageLabels = [
      '20以下',
      '21-25',
      '26-30',
      '31-35',
      '36-40',
      '41-45',
      '46-50',
      '51-55',
      '56-60',
      '61-65',
      '65+',
      '其他',
    ]

    const getOptions = (series) => {
      return generateAgeConfig({
        labels: ageLabels,
        series: series || [],
      })
    }

    const sumValue = (array = []) => {
      const res = array.reduce((prev, curr) => {
        return prev + curr
      }, 0)
      return truncateDecimal(res)
    }

    const getChart = async ({ interval }) => {
      const res = await getAgeData({
        shopId: shopId.value,
        interval,
        ageLabels,
      })
      chartData.sumAmount = res.sumAmount
      chartData.count = res.count
      chartData.avgAmount = res.avgAmount
      chartData.avgMemberCount = res.avgMemberCount
    }

    return {
      chartData,
      getOptions,
      sumValue,
      getChart,
      ArrowRight,
    }
  },
})
</script>

<style lang="postcss" scoped>
.age-consumption-statistics {
  @apply flex flex-col gap-5;
}
</style>
