<template>
  <div class="donut-chart-block">
    <DonutChartTitle :title="title" :hint="hint" :subtitle="subtitle" />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue'
import DonutChartTitle from './DonutChartTitle.vue'
import { useChart } from '@/use/dashboard'

export default defineComponent({
  name: 'DonutChartBlock',
  components: { DonutChartTitle },
  props: {
    options: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
  setup (props) {
    const { chartRef, updateChart } = useChart(props.options)

    watch(
      () => props.options,
      () => {
        updateChart(props.options)
      },
    )
    return {
      chartRef,
    }
  },
})
</script>

<style lang="postcss" scoped>
.donut-chart-block {
  @apply p-[20px];
}
</style>
