<template>
  <div class="donut-chart-title">
    <div v-if="!hint" class="title">{{ title }}</div>
    <FormItemTooltipLabel v-else :label="title" :tooltipWidth="200" class="title">
      {{ hint }}
    </FormItemTooltipLabel>
    <div class="subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'

export default defineComponent({
  name: 'DonutChartTitle',
  components: { FormItemTooltipLabel },
  props: {
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
})
</script>

<style lang="postcss" scoped>
.donut-chart-title {
  @apply pb-5;
  @apply border-b border-[#ececec];
}
.title {
  @apply text-gray-80;
}
.subtitle {
  @apply text-[28px] text-primary-100;
}
</style>
